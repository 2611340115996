'use client'

import classnames from 'classnames'
import styles from './ContentWithStickyNavigation.module.scss'
import { useContext, useEffect, useRef, useState } from 'react'
import { ScrollContext } from '@/context/Scroll'
import useI18n from '@/hooks/use-i18n'
import useWindowResize from '@/hooks/use-window-resize'
import useStore from '@/store'

export type ContentWithStickyNavigationLineItem = {
  title: string
  id: string
  count?: number
}

type ContentWithStickyNavigation = {
  className?: string
  children: React.ReactNode
  lineItems: ContentWithStickyNavigationLineItem[]
  contentGroupQuerySelector: string
  widerNav?: boolean
  bottomNavContent?: React.ReactNode
  sectionBackground: SectionBackground
}

// eslint-disable-next-line
const ContentWithStickyNavigation = ({
  className,
  lineItems,
  children,
  contentGroupQuerySelector,
  widerNav,
  bottomNavContent,
  sectionBackground,
}: ContentWithStickyNavigation) => {
  const { scroll, onScrollCallback } = useContext(ScrollContext)
  const [activeId, setActiveId] = useState<string>('')
  const { i18n } = useI18n()
  const resizeKey = useWindowResize()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const setStickyMobileNavContent = useStore(state => state.setStickyMobileNavContent)
  const removeStickyMobileNavById = useStore(state => state.removeStickyMobileNavById)
  const setStickyMobileNavActiveItemsById = useStore(state => state.setStickyMobileNavActiveItemsById)
  const [id, setId] = useState<string>('')

  const handleNavigationClick = (id: string) => {
    const listElement = document.getElementById(id)
    if (!id || !scroll || !listElement) return
    if (listElement instanceof HTMLElement) {
      const offset = listElement.getBoundingClientRect().top + document.documentElement.scrollTop
      scroll.scrollTo(offset + 1, { duration: 0.6 })
    }
  }

  useEffect(() => {
    setId(`id_${Math.random().toString().replace('.', '')}`)
  }, [])

  useEffect(() => {
    if (!id) return

    setTimeout(() => {
      setStickyMobileNavContent({
        items: lineItems,
        container: containerRef.current as HTMLElement,
        bottomNavContent,
        sectionBackground,
      })
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems, bottomNavContent, sectionBackground, id])

  useEffect(() => {
    return () => {
      removeStickyMobileNavById(id)

      onScrollCallback({
        key: `scroll_${id}`,
        remove: true,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (!lineItems.length) return
    if (!scroll) return
    if (!id) return

    onScrollCallback({
      key: `scroll_${id}`,
      remove: true,
    })

    onScrollCallback({
      key: `scroll_${id}`,
      callback: e => {
        const items = document.querySelectorAll(contentGroupQuerySelector)
        const currentScroll = e.targetScroll

        let activeId = lineItems[0].id

        items.forEach(el => {
          if (el instanceof HTMLElement) {
            const offset = el.getBoundingClientRect().top + document.documentElement.scrollTop
            if (currentScroll > offset) {
              activeId = el.id || ''
            }
          }
        })

        setStickyMobileNavActiveItemsById({
          id: id,
          activeId: activeId,
        })
        setActiveId(activeId)
      },
    })
  }, [scroll, lineItems, contentGroupQuerySelector, resizeKey, id, setStickyMobileNavActiveItemsById, onScrollCallback])

  if (!lineItems?.length || !children || !contentGroupQuerySelector) return null

  return (
    <div
      id={id}
      className={classnames(styles.ContentWithStickyNavigation, className, { [styles.widerNav]: widerNav })}
      ref={containerRef}
    >
      <div className={styles.inner}>
        <div className={styles.grid}>
          <div className={styles.navigationSide}>
            <nav className={styles.navigation}>
              <div
                className={styles.navigation__inner}
                data-lenis-prevent
              >
                <div className={styles.navigationItems}>
                  {lineItems.map((lineItem, i) => (
                    <div
                      className={classnames(styles.navigation__item, {
                        [styles.isActive]: activeId === lineItem.id,
                      })}
                      key={i}
                    >
                      <button
                        className={styles.navigation__button}
                        aria-label={i18n('goToCategory')}
                        onClick={() => {
                          handleNavigationClick(lineItem.id)
                        }}
                      >
                        <span className={styles.navigation__buttonText}>{lineItem.title}</span>
                        {lineItem.count && (
                          <span className={styles.navigation__buttonCount}>&nbsp;({lineItem.count})</span>
                        )}
                      </button>
                    </div>
                  ))}
                </div>
                {bottomNavContent && <div>{bottomNavContent}</div>}
              </div>
            </nav>
          </div>
          <div className={styles.listSide}>{children}</div>
        </div>
      </div>
    </div>
  )
}

ContentWithStickyNavigation.displayName = 'ContentWithStickyNavigation'

export default ContentWithStickyNavigation
