'use client'

import classnames from 'classnames'
import styles from './ContactForm.module.scss'
import ContactForm from '@/components/ContactForm/ContactForm'
import Widont from '@/components/Widont/Widont'

const ContactFormSection = ({ className, subtitle, title }: SanityContactForm) => {
  if (!title) return null

  return (
    <div className={classnames(styles.ContactForm, className)}>
      <div className={classnames(styles.inner)}>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>
            <Widont text={title} />
          </h1>
          {subtitle && (
            <p className={styles.subtitle}>
              <Widont text={subtitle} />
            </p>
          )}
        </div>
        <div className={styles.formContainer}>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

ContactFormSection.displayName = 'ContactFormSection'

export default ContactFormSection
