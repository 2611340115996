import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import { getLocationListItemId, getRestaurantAddressString } from '@/utils'
import styles from './LocationHero.module.scss'
import useI18n from '@/hooks/use-i18n'
import { DOC_TYPES } from '@/data'
import Button from '@/components/Button/Button'
import LocationHeroHours from '@/sections/LocationHero/LocationHeroHours/LocationHeroHours'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'
import { useEffect } from 'react'
import useStore from '@/store'
import EmailSignupInput from '@/components/EmailSignupInput/EmailSignupInput'
import useBreakpoint from '@/hooks/use-breakpoint'
import LocationHeroImageCarousel from '@/sections/LocationHero/LocationHeroImageCarousel/LocationHeroImageCarousel'

export const LOCATION_HERO_INPUT_ID = 'locationHeroInputNewsletter'

const LocationHero = (props: LocationHero) => {
  const {
    title,
    areaTitle,
    phoneNumber,
    reservationLinks,
    serviceLinks,
    googleMapsLink,
    streetAddress,
    city,
    state,
    postalCode,
    featuredImage,
    hours,
    timeZone,
    images,
    isComingSoon,
    holidayHours,
  } = props
  const { i18n } = useI18n()
  const setActiveRestaurant = useStore(state => state.setActiveRestaurant)
  const { isMobile } = useBreakpoint()
  const addressString = getRestaurantAddressString({
    streetAddress,
    city,
    state,
    postalCode,
  })
  const breadcrumbLinks: SanityLink[] = [
    {
      label: i18n('all'),
      linkType: 'internal',
      link: {
        _type: DOC_TYPES.PAGE,
        slug: 'locations',
      },
    },
    {
      label: i18n(state as string),
      linkType: 'internal',
      link: {
        _type: DOC_TYPES.PAGE,
        slug: 'locations',
      },
      hash: getLocationListItemId(state as string),
    },
  ]

  useEffect(() => {
    setActiveRestaurant(props)
  }, [setActiveRestaurant, props])

  if (!title) return null

  return (
    <div className={styles.LocationHero}>
      <div className={styles.inner}>
        <div className={styles.textSide}>
          <Breadcrumbs
            links={breadcrumbLinks}
            className={styles.breadcrumbs}
          />
          {isComingSoon && <p className={styles.comingSoonText}>{i18n('comingSoon')}</p>}
          <h1 className={styles.title}>{title}</h1>
          {areaTitle && (
            <p className={styles.areaTitle}>
              <span className={styles.areaTitle__at}>{i18n('at')}&nbsp;</span>
              <span className={styles.areaTitle__title}>{areaTitle}</span>
            </p>
          )}
          {isComingSoon && (
            <div className={styles.emailSignupContainer}>
              <p className={styles.emailSignupContainer__text}>
                <span>{i18n('beTheFirstToDine')}</span>
                <span>
                  <strong>&nbsp;{i18n('enterEmailBelow')}</strong>
                </span>
              </p>
              <EmailSignupInput
                className={styles.emailSignupContainer__emailInput}
                variant="simple"
                inputId={LOCATION_HERO_INPUT_ID}
              />
            </div>
          )}
          {!isComingSoon && (
            <div className={styles.buttons}>
              {reservationLinks && reservationLinks?.length > 0 && (
                <OrderDropdown
                  serviceLinks={reservationLinks}
                  primary
                  isReserve
                  dropdownPosition={'bottomLeft'}
                />
              )}
              {serviceLinks && serviceLinks?.length > 0 && (
                <OrderDropdown
                  serviceLinks={serviceLinks}
                  dropdownPosition={isMobile ? 'bottomRight' : 'bottomLeft'}
                />
              )}
            </div>
          )}
          <div className={styles.contactMethodsContainer}>
            <div className={styles.contactMethodsContainer__left}>
              {phoneNumber && <p className={styles.phoneNumber}>{i18n('phoneNumberShort', { phoneNumber })}</p>}
              {addressString && (
                <p className={styles.address}>
                  {i18n('addressShort', {
                    address: addressString,
                  })}
                </p>
              )}
            </div>
            <div className={styles.contactMethodsContainer__right}>
              {googleMapsLink && (
                <Button
                  link={{
                    label: i18n('findUs'),
                    linkType: 'external',
                    link: googleMapsLink,
                  }}
                  plain
                  icon="caretRight"
                />
              )}
            </div>
          </div>
          {!isComingSoon && (
            <div className={styles.hoursContainer}>
              {hours && timeZone && (
                <LocationHeroHours
                  hours={hours}
                  timeZone={timeZone}
                  holidayHours={holidayHours}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.imageSide}>
          {featuredImage && <LocationHeroImageCarousel images={[featuredImage, ...(images as SanityImage[])]} />}
        </div>
      </div>
    </div>
  )
}

LocationHero.displayName = 'LocationHero'

export default LocationHero
